import { isEmpty, map } from 'lodash-es'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useFeatureFlag } from '_shared_/feature-flag'
import { useSettings } from '_shared_/settings/useSettings'
import type { FreightInformation } from 'components/FreightInformation'
import { EQUIPMENT_REQUIREMENTS_NAME } from 'components/FreightInformation/FreightInformationTemperature'
import type { HandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import {
  createCommodity,
  createHandlingUnit,
  createHandlingUnitOrderItem,
  createTransientCommodity,
  createTransientHandlingUnit,
  createTransientHandlingUnitOrderItem,
} from 'components/HandlingUnitsManager/HandlingUnitsUtils'
import type { Stop } from 'components/StopsManager'
import type { ListOrder, ListOrderItem } from 'orders/types'
import { getOrderItemsLowestTemperature } from 'screens/Orders/common/utils'
import type { OrderGroupingMode } from 'screens/Orders/ManualConsolidation/useSelectedOrders'
import {
  ORDER_GROUPING_MULTIPLE_OPTION,
  ORDER_GROUPING_SINGLE_OPTION,
} from 'screens/Orders/ManualConsolidation/useSelectedOrders'
import { useSelectedOrders } from 'screens/Shipper/Shipments/components/OrderSelection/useSelectedOrders'
import type { TransientShipment } from 'screens/Shipper/Shipments/components/Shipment'
import { EQUIPMENT_TYPES } from 'utils/equipmentTypeV2'
import { joinValidStrings } from 'utils/strings'
import { resolveTransportationMode } from 'utils/transportationMode'

import { presetStopToFormTransientStop, usePreSetStops } from './usePreSetStops'

function mapOrdersToHandlingUnits(
  orders: ListOrder[],
  stops: Stop[] | null,
  setShippedCount: boolean = false
): HandlingUnit[] {
  return orders.flatMap((order): HandlingUnit[] => {
    return order.items.map((item): HandlingUnit => {
      const pickup = stops?.find(
        (stop) => stop.facility?.uuid == order.pickup_facility_uuid
      )?.stop_index
      const delivery = stops?.find(
        (stop) => stop.facility?.uuid == order.delivery_facility_uuid
      )?.stop_index

      return createHandlingUnit({
        pickup_stop_index: pickup || 0,
        delivery_stop_index: delivery || null,
        package_count: item.package_count,
        package_type: item.package_type,
        length: item.package_unit_length || '',
        width: item.package_unit_width || '',
        height: item.package_unit_height || '',
        stackable: item.stackable,
        turnable: item.turnable,
        commodities: [
          createCommodity({
            description: item.commodity,
            weight: item.total_weight || '',
            freight_class: item.freight_class || null,
            nmfc_code: item.nmfc_code,
          }),
        ],
        order_items: [
          createHandlingUnitOrderItem({
            uuid: item.uuid,
            line_number: item.line_number,
            item_description: item.item_description,
            item_number: item.item_number,
            shipped_package_count: setShippedCount
              ? item.package_count
              : undefined,
            order: {
              uuid: order.uuid,
              primary_ref: order.primary_ref,
              external_id: order.external_id,
              po_number: order.po_number,
              so_number: order.so_number,
            },
          }),
        ],
      })
    })
  })
}

function getTemperatureRequirements(
  lowestTemperature: string | undefined,
  previousShipment: TransientShipment
) {
  return isEmpty(lowestTemperature)
    ? {}
    : {
        equipment_type: EQUIPMENT_TYPES.rfr.abbr,
        [EQUIPMENT_REQUIREMENTS_NAME]: {
          ...previousShipment[EQUIPMENT_REQUIREMENTS_NAME],
          temperature: Number(lowestTemperature),
        },
      }
}

export function useOrderConsolidation({
  enable,
  setShipment,
}: {
  enable: boolean
  setShipment: Dispatch<SetStateAction<TransientShipment>>
}) {
  const enableOrders = useFeatureFlag('ENABLE_ORDERS')
  const {
    values: [enableShippedCount],
  } = useSettings(['flags.ENABLE_ORDER_SHIPPED_COUNT'])
  const { stops, loadingFacilities } = usePreSetStops({
    enabled: enable && enableOrders,
  })
  const { selectedOrders } = useSelectedOrders({ enable })
  const [stopsLoaded, setStopsLoaded] = useState(false)
  const [itemsLoaded, setItemsLoaded] = useState(false)
  const [freightInfoLoaded, setFreightInfoLoaded] = useState(false)

  const location = useLocation()

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const consolidationMode: OrderGroupingMode = useMemo(() => {
    const consolidation_mode = searchParams.get('consolidation_mode')
    return consolidation_mode === ORDER_GROUPING_SINGLE_OPTION
      ? ORDER_GROUPING_SINGLE_OPTION
      : ORDER_GROUPING_MULTIPLE_OPTION
  }, [searchParams])

  const mode = useMemo(() => {
    return resolveTransportationMode(searchParams.get('mode') || '')
  }, [searchParams])

  const handlingUnits = useMemo(() => {
    if (
      consolidationMode == ORDER_GROUPING_SINGLE_OPTION &&
      selectedOrders.length > 0 &&
      !loadingFacilities
    ) {
      return mapOrdersToHandlingUnits(
        selectedOrders,
        stops,
        Boolean(enableShippedCount)
      )
    }
    return []
  }, [
    enableShippedCount,
    consolidationMode,
    selectedOrders,
    stops,
    loadingFacilities,
  ])

  const freightInformation: Partial<FreightInformation> = useMemo(() => {
    if (!isEmpty(selectedOrders)) {
      return {
        po_number: joinValidStrings(map(selectedOrders, 'po_number'), ', '),
        so_number: joinValidStrings(map(selectedOrders, 'so_number'), ', '),
        shipper_ref_number: joinValidStrings(
          map(selectedOrders, 'primary_ref'),
          ', '
        ),
        ...(mode ? { mode } : {}),
      }
    }
    return {}
  }, [selectedOrders, mode])

  //Set pre selected stops
  useEffect(() => {
    if (!stopsLoaded && !isEmpty(stops) && !loadingFacilities) {
      setShipment((previous) => {
        return {
          ...previous,
          stops: stops!.map(presetStopToFormTransientStop),
        }
      })
      setStopsLoaded(true)
    }
  }, [loadingFacilities, stopsLoaded, stops, setShipment])

  // Set freight information from selected Orders
  useEffect(() => {
    if (!freightInfoLoaded && !isEmpty(freightInformation)) {
      setShipment((previous) => ({
        ...previous,
        ...freightInformation,
      }))
      setFreightInfoLoaded(true)
    }
  }, [freightInfoLoaded, freightInformation, selectedOrders, setShipment])

  const lowestTemperature = useMemo(() => {
    if (selectedOrders.length > 0) {
      const orderItems = selectedOrders.reduce<ListOrderItem[]>(
        (array, order) => [...array, ...order.items],
        []
      )
      const { max_temperature } = getOrderItemsLowestTemperature(orderItems)

      return max_temperature
    }

    return undefined
  }, [selectedOrders])

  //Set handling units from selected Orders' items
  useEffect(() => {
    if (!itemsLoaded && !isEmpty(handlingUnits)) {
      setShipment((previousShipment) => ({
        ...previousShipment,
        ...getTemperatureRequirements(lowestTemperature, previousShipment),
        items: handlingUnits.map((handlingUnit) => ({
          ...createTransientHandlingUnit(handlingUnit),
          commodities: handlingUnit.commodities.map((commodity) =>
            createTransientCommodity(commodity)
          ),
          order_items: handlingUnit.order_items?.map((orderItem) =>
            createTransientHandlingUnitOrderItem(orderItem)
          ),
        })),
      }))
      setItemsLoaded(true)
    }
  }, [itemsLoaded, lowestTemperature, setShipment, handlingUnits])

  return { ordersInConsolidation: selectedOrders, consolidationMode }
}
