import type { RouteComponentProps } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import { GlobalSpinner } from '_shared_/components/GlobalSpinner'
import { useFeatureFlag } from '_shared_/feature-flag'

import ViewOrderPage from './details/ViewOrderPage'
import ListOrdersPage from './list/ListOrdersPage'

export function OrdersRoutes({ match }: Readonly<RouteComponentProps>) {
  const { path } = match

  const enableOrders = useFeatureFlag('ENABLE_ORDERS')

  if (!enableOrders) {
    return <GlobalSpinner />
  }

  return (
    <Switch>
      <Route path={`${path}/`} exact component={ListOrdersPage} />
      <Route path={`${path}/:id`} exact component={ViewOrderPage} />
    </Switch>
  )
}
