// TODO: Move services/facilities.ts to facilities/facilities.services.ts

import type { AxiosRequestConfig } from 'axios'

import { FACILITIES_V2_ENDPOINT } from 'services/facilities'
import httpClient from 'utils/httpClient'

import type { Terminal } from './facitilites.types'

export type FacilitySharingMethods = 'all' | 'specific-facilities-to-suppliers'

export type UploadSharingSettingsPayload =
  | {
      facilities_uuids: string[]
      sharing_method: 'all'
    }
  | {
      facilities_uuids: string[]
      sharing_method: 'specific-facilities-to-suppliers'
      suppliers_uuids: string[]
    }

export async function updateSharingSettings({
  payload,
  config,
}: {
  payload: UploadSharingSettingsPayload
  config?: Omit<AxiosRequestConfig, 'data'>
}) {
  const { data } = await httpClient.patch(
    `${FACILITIES_V2_ENDPOINT}/sharing`,
    payload,
    config
  )

  return data
}

export async function getTerminals(config?: Omit<AxiosRequestConfig, 'data'>) {
  const { data } = await httpClient.get<PaginatedResult<Terminal>>(
    `${FACILITIES_V2_ENDPOINT}/terminals`,
    config
  )

  return data
}

export async function getSharingSettings(
  facilityUUID: string,
  config?: AxiosRequestConfig
) {
  const { data } = await httpClient.get(
    `${FACILITIES_V2_ENDPOINT}/${facilityUUID}/sharing-list`,
    config
  )

  return data
}
