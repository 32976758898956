import { Card, Layout, Text } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash-es'

import { useFeatureFlag } from '_shared_/feature-flag'
import { formatWeight } from 'components/ShippingItemsManager'
import { plural } from 'utils/strings'

import type {
  HandlingUnit,
  HandlingUnitsDetails,
  TransientHandlingUnit,
} from './HandlingUnits.types'
import { HandlingUnitsSummary } from './HandlingUnitsSummary'
import {
  calculateHandlingUnits,
  calculateTotalWeight,
} from './HandlingUnitsUtils'

export function StopHandlingUnitsSummary({
  variant,
  handlingUnits,
}: {
  readonly variant: 'pickup' | 'delivery'
  readonly handlingUnits: (HandlingUnitsDetails | TransientHandlingUnit)[]
}) {
  if (isEmpty(handlingUnits)) {
    return null
  }

  function getKeyId(
    handlingUnit: HandlingUnitsDetails | TransientHandlingUnit
  ) {
    return `${(handlingUnit as TransientHandlingUnit)._metadata?.id || (handlingUnit as HandlingUnitsDetails).id}`
  }

  const totalUnits = calculateHandlingUnits(handlingUnits as HandlingUnit[])
  const totalWeight = calculateTotalWeight(handlingUnits as HandlingUnit[])
  return (
    <Layout.Stack gap="spacing-4">
      <Layout.Box padding="none">
        <Text>
          {handlingUnits.length} {plural('item', handlingUnits.length)}
        </Text>
        <Text color="color-text-tertiary">
          {' '}
          being {variant == 'pickup' ? 'picked up' : 'delivered'} at this
          facility • {totalUnits} H/U • Total weight
        </Text>
        <Text>{formatWeight(totalWeight)}</Text>
      </Layout.Box>
      <Layout.Group>
        {handlingUnits.map((handlingUnit) => (
          <HandlingUnitInStopSummary
            key={`item-${getKeyId(handlingUnit)}-${handlingUnit.pickup_stop_index}-${handlingUnit.delivery_stop_index}`}
            variant={variant}
            handlingUnit={handlingUnit}
          />
        ))}
      </Layout.Group>
    </Layout.Stack>
  )
}

export function HandlingUnitInStopSummary({
  variant,
  handlingUnit,
}: {
  readonly variant: 'pickup' | 'delivery'
  readonly handlingUnit: HandlingUnitsDetails | TransientHandlingUnit
}) {
  const enableOrders = useFeatureFlag('ENABLE_ORDERS')

  return (
    <Card style={{ maxWidth: '13rem' }}>
      <Card.Body>
        <Layout.Stack gap="spacing-2">
          <HandlingUnitsSummary value={handlingUnit}>
            {variant == 'delivery' && <HandlingUnitsSummary.Origin />}
            <HandlingUnitsSummary.Description />
            {enableOrders && (
              <>
                <HandlingUnitsSummary.OrderPrimaryRefs />
                {handlingUnit.fulfillment && (
                  <HandlingUnitsSummary.FulfillmentRefNumber />
                )}
                <HandlingUnitsSummary.PONumber />
                <HandlingUnitsSummary.SONumber />
              </>
            )}
          </HandlingUnitsSummary>
        </Layout.Stack>
      </Card.Body>
    </Card>
  )
}
